import { graphql } from "gatsby";
import React from "react";
import LegalDocument from "../../components/LegalDocument";
import NavigationBarSpacer from "../../components/NavigationBarSpacer";
import TableOfContents from "../../components/TableOfContents";
import Layout from "../../containers/Layout";
import SEO from "../../components/Seo";
import Section from "../../containers/Section";

const LegalTemplate = props => {
  const legalDocument = props?.data?.legaldocs?.nodes[0];
  const locale = props?.pageContext?.language;

  return (
    <Layout locale={locale}>
      <SEO
        title={
          legalDocument?.seoSettings?.metadataTitle || legalDocument?.title
        }
        description={legalDocument?.seoSettings?.metadataDescription}
        image={legalDocument?.seoSettings?.openGraphImage?.url}
        canonical={legalDocument?.seoSettings?.canonicalUrl}
        robots={legalDocument?.seoSettings?.robots}
      />
      <NavigationBarSpacer />
      <Section className="max-w-8xl mx-auto flex flex-col lg:flex-row justify-center mt-20">
        {legalDocument?.removeToc !== true && (
        <TableOfContents
          type="legaldocument"
          content={legalDocument?.text?.raw}
        />
        )}
        <LegalDocument json={legalDocument?.text} />
      </Section>
    </Layout>
  );
};

export default LegalTemplate;

export const pageQuery = graphql`
  query ($language: String, $urlPath: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    legaldocs: allContentfulLegalDocument(
      filter: { urlPath: { eq: $urlPath }, node_locale: { eq: $language } }
    ) {
      nodes {
        id
        urlPath
        title
        node_locale
        effectiveDate
        text {
          raw
        }
        removeToc
        seoSettings {
          metadataTitle
          metadataDescription
          openGraphImage {
            gatsbyImageData
            url
          }
          canonicalUrl
          robots
        }
      }
    }
  }
`;
