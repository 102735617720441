import React from "react";
import RichText from "../RichText";

class LegalDocument extends React.Component {
  render() {
    return (
      <div className="max-w-3xl">
        {this?.props?.json && <RichText data={this.props.json} />}
      </div>
    );
  }
}

export default LegalDocument;
